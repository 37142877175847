import React, {FC, memo, useEffect, useState} from "react";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkHeader from "../../components/particles/TkHeader";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import {useTkOrder} from "../../context/TkContext";
import {Order, SellerIntegrationModel} from "../../models/order";
import "./style.scss";
import dayjs from "dayjs";
import {formatMoney} from "../../utils/number-utils";
import {Link} from "react-router-dom";
import {ITkProductQuantityModel} from "../../models/product";
import TkProportionalImage from "../../components/particles/TkProportionalImage";
import {tk_route_product_detail} from "../TkProductDetailView";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import {smoothScroll} from "../../utils/utils";
import {tk_route_account} from "../TkMyAccountView";
import {tk_route_exchanges_and_devolution} from "../TkExchangesAndDevolutionView";
import {tkAddressWithdraw, tkLinkMapWithdraw} from "../TkWithdrawPolicyView";
import {isMediaSm} from "../../utils/screen-utils";

const NoPhoto = 'https://teky.s3.sa-east-1.amazonaws.com/no-image.svg';

export const tk_route_my_orders = "/sua-conta/meus-pedidos";

const OrderItemsArea: React.FC<{
  productQuantity: ITkProductQuantityModel,
  integration?: SellerIntegrationModel,  
  shipment?: any
  createdAt?: Date
}> = memo(({productQuantity, integration, shipment, createdAt}) => {
  const {name, thumbnail, manufacturerName, leadTime} = productQuantity.product;
  const {integrationType, deadlineInDays, backorderDeadlineInDays} = integration
  const deliveryDate = () => {
    let date = new Date(createdAt)
    if(leadTime && productQuantity.backorderQuantity > 0 && backorderDeadlineInDays)
      return dayjs(date).add(backorderDeadlineInDays, 'day').format('DD/MM/YYYY')
    if(deadlineInDays)
      return dayjs(date).add(deadlineInDays, 'day').format('DD/MM/YYYY')
    //Codigo Legado para pedidos antigos, antes da readequação da lógica de datas
    if(integrationType === "PICKUP") {
      if(leadTime && productQuantity.backorderQuantity > 0) date = dayjs(date).add(leadTime, 'day').toDate()
      const dayOfWeek = date.getDay()
      switch (dayOfWeek) {
        case 5: 
          date = dayjs(date).add(3, 'day').toDate()
          break;
        case 6:
          date = dayjs(date).add(2, 'day').toDate()
          break;
        case 0:
          date = dayjs(date).add(1, 'day').toDate()
          break;
        default:
          break;
      }

      return dayjs(date).add(1, 'day').format('DD/MM/YYYY')
    }
    if(shipment){
      return dayjs(date).add(shipment.deadlineInDays, 'day').format('DD/MM/YYYY')
    }
    return null
  }

  return (
    <>
      <Link to={tk_route_product_detail(productQuantity.product)}>
        <TkProportionalImage url={!!thumbnail ? thumbnail : NoPhoto} plainB={NoPhoto}/>
      </Link>
      <div>
        <Link to={tk_route_product_detail(productQuantity.product)}>
          <h3>
            {name}
          </h3>
        </Link>
        <p>
          por <b>{manufacturerName}</b>
        </p>
        <dl>
          <dt>Preço unitário</dt>
          <dd>{formatMoney(productQuantity.price)}</dd>
          <dt>Quantidade</dt>
          <dd>{productQuantity.quantity}</dd>
          <dt>Subtotal (item)</dt>
          <dd>{formatMoney(productQuantity.total)}</dd>  
          {!isMediaSm() && <>
            <dt>Tipo de Entrega</dt>
            <dd className="deliveryType">{integrationType=="PICKUP" ? "Retirada" : "Entrega"} {productQuantity.backorderQuantity > 0 ? "sob Encomenda" : ""}</dd>
            <dt>Data de {integrationType=="PICKUP" ? "Retirada" : "Entrega"}</dt>
              <dd className="deliveryDate">
                {deliveryDate() ? 
                <time dateTime={deliveryDate().toString()}>
                  {deliveryDate()}
                </time> : "Aguardando confirmação"}
                </dd>
          </>}
        </dl>
        {isMediaSm() && <>
          <dl>
            <dt>Tipo de Entrega</dt>
            <dd className="deliveryType">{integrationType=="PICKUP" ? "Retirada" : "Entrega"} {productQuantity.backorderQuantity > 0 ? "sob Encomenda" : ""}</dd>
            <dt>Data de {integrationType=="PICKUP" ? "Retirada" : "Entrega"}</dt>
              <dd className="deliveryDate"><time dateTime={deliveryDate().toString()}>
                {deliveryDate()}
              </time></dd>
          </dl>
          </>}
          {!isMediaSm() && <Link
          to={tk_route_product_detail(productQuantity.product)}
          className="button-2">Comprar novamente</Link>}
      </div>
      {isMediaSm() && <Link
          to={tk_route_product_detail(productQuantity.product)}
          className="button-2">Comprar novamente</Link>}
    </>
  );
});

const OrderAddress:FC<{
  order: Order;
}> = ({order}) => {
  const {percentWithdraw} = useTkOrder()

  const getShipmentAddress = () => {
    if (order.company) {
      return `${order.company.address.location} ${order.company.address.locationNumber}`
    }

    return `${order.orderUser.address.location} ${order.orderUser.address.locationNumber}`
  }

  const address = () => {
    const perc = percentWithdraw(order)
    if (perc === 0) {
      return <dd>
        <address>{getShipmentAddress()}</address>
      </dd>
    }

    if (perc === 100) {
      return <dd>
        <address>{tkAddressWithdraw()}</address>
      </dd>
    }

    return <dd>
      <address><b>Entrega:</b> {getShipmentAddress()}</address>
      <address><b>Retirada:</b> {tkAddressWithdraw()}</address>
    </dd>
  }

  const getTitle = () => {
    const perc = percentWithdraw(order)
    if (perc === 0) return 'Endereço de entrega'
    if (perc === 100) return 'Endereço de retirada'

    return 'Endereço de entrega / Endereço de retirada'
  }

  return <>
    <dt>{getTitle()}</dt>
    {address()}
  </>
}

const OrderArea: React.FC<{
  order: Order;
}> = memo(({order}) => {
  const {percentWithdraw} = useTkOrder()
  const [showItems, setShowItems] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const percWithdraw = percentWithdraw(order)

  const paymentStatus = (status: any) => {
    switch (status) {
      case "APPROVED":
        return "Confirmado";
      case "CANCELED":
        return "Pedido Cancelado";
      case "REFUNDED":
        return "Reembolsado";
      default:
        return "Aguardando confirmação";
    }
  }

  const orderStatus = (status: any) => {
    const perc = percentWithdraw(order)
    if (perc === 0) {
      return orderStatusDelivery(status)
    }
    if(perc === 100) {
      return orderStatusWithdraw(status)
    }
    return orderStatusMixed(status)
  }

  const orderStatusDelivery = (status: any) => {
    switch (status) {
      case "PREPARATION":
        return "Em separação";
      case "INVOICE_CREATED":
        return "Em transporte";
      case "IN_ROUTE":
        return "Em transporte";
      case "DELIVERED":
        return "Pedido entregue";
      default:
        return "Aguardando confirmação do fornecedor";
    }
  }

  const orderStatusWithdraw = (status: any) => {
    switch (status) {
      case "PREPARATION":
        return "Em separação";
      case "INVOICE_CREATED":
        return "Aguardando retirada";
      case "IN_ROUTE":
        return "Aguardando retirada";
      case "DELIVERED":
        return "Pedido Retirado";
      default:
        return "Aguardando confirmação do fornecedor";
    }
  }

  const orderStatusMixed = (status: any) => {
    switch (status) {
      case "PREPARATION":
        return "Em separação";
      case "INVOICE_CREATED":
        return "Em transporte / Aguardando retirada";
      case "IN_ROUTE":
        return "Em transporte / Aguardando retirada";
      case "DELIVERED":
        return "Pedido entregue";
      default:
        return "Aguardando confirmação do fornecedor";
    }
  }

  const handleShowSuccessMessage = () => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 2000);
  }

  return <article className="TkMyOrdersView">
    <header>
      <h2>Pedido {order.reference}</h2>
      <dl>
        <dt>Realizado em</dt>
        <dd>
          <time dateTime={order.createdAt.toString()}>
            {dayjs(order.createdAt).format(
              "DD/MM/YYYY HH:mm"
            )}
          </time>
        </dd>
        <dt>Total do pedido</dt>
        <dd>
          <strong>
            {formatMoney(
                order?.coupon && order?.totalValueWithSavings > 0
                  ? order.totalValueWithSavings + (order.shipment.value || 0)
                    : order?.totalValueWithFees
                        ? order.totalValueWithFees
                        : order.totalValue
            )}
          </strong>
        </dd>
        <OrderAddress order={order}/>
        <dt>Status pagamento</dt>
        <dd>
          <b>{paymentStatus(order.payment.status)}</b>
        </dd>
        {order.payment.status === "APPROVED" && <>
        <dt>Status pedido</dt>
            <dd>
                <b>{orderStatus(order.shipment.status)}</b>
            </dd>
        </>}
        {order.payment.status !== "APPROVED" && <>
            <dt/>
            <dd/>
        </>}
      </dl>
      <button type="button" onClick={() => setShowItems(!showItems)}>
        Detalhes do pedido&nbsp;
        <span className={!showItems ? "closed-details" : ""}>&gt;</span>
      </button>
    </header>
    {showItems && <>
        <ul>
            <div className="itemsList">
              {order.integrations?.map(integration => (<>
                {integration.items.map((pq: ITkProductQuantityModel, idx) => <li key={idx}>
                  <OrderItemsArea
                    productQuantity={pq}
                    integration={integration}
                    shipment={order.shipment}
                    createdAt={order.createdAt}
                  />
                </li>)}
              </>))}
            </div>

            <div className="paymentWrapper">
                <div className="paymentContent">
                  {order.payment.status !== "APPROVED" && (
                    <>
                      {order.payment.method === "pix" && (
                        <>
                          <div>
                            <img
                              src={order.payment.pixQrCode}
                              className="imgQrCode"
                              alt="QrCode"
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                order.payment.pixQrCodeText
                              );
                              handleShowSuccessMessage();
                            }}
                            className="button"
                            style={{width: "100%", margin: "10px 0"}}
                          >
                            Copiar Qr Code
                          </button>
                        </>
                      )}
                      {order.payment.method === "bank_slip" && (
                        <>
                          <Link
                            to={{pathname: `${order.payment.billingUrl}.pdf`}}
                            target="_blank"
                            className="button"
                          >
                            Visualizar Boleto
                          </Link>
                          <button
                            type="button"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                order.payment.bankSlipTypeableLine
                              );
                              handleShowSuccessMessage();
                            }}
                            className="button"
                            style={{width: "100%", margin: "10px 0"}}
                          >
                            Copiar Código de Barras
                          </button>
                        </>
                      )}
                      {showSuccessMessage && (
                        <div
                          className="alert alert-success"
                          style={{width: "100%"}}
                        >
                          Código copiado com sucesso!
                        </div>
                      )}
                    </>
                  )}
                    <Link to={tk_route_exchanges_and_devolution} className="button">
                      {order.integrations?.some(x => x.items.length > 1)
                        ? "Devolver produtos"
                        : "Devolver produto"}
                    </Link>
                  {percWithdraw > 0 && <a target='_blank' href={tkLinkMapWithdraw()} className="button m-t-10px">
                    Local de retirada no mapa
                  </a>}
                </div>
            </div>
        </ul>
    </>
    }
  </article>
});

const TkMyOrdersView: React.FC = () => {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  const [loading, setLoading] = useState(true);
  const [months] = useState([{
    start: now.getTime() - oneDay * 7,
    label: "Últimos 7 dias",
  }, {
    start: now.getTime() - oneDay * 30,
    label: "Últimos 30 dias",
  }, {
    start: now.getTime() - oneDay * 90,
    label: "Últimos 3 meses",
  }, {
    start: now.getTime() - oneDay * 180,
    label: "Últimos 6 meses",
  }, {
    start: 0,
    label: "Todos os pedidos",
  },
  ]);
  const [orders, setOrders] = useState<Order[]>([]);
  const [state, setState] = useState({
    start: now.getTime() - oneDay * 7,
  });
  const {getLastOrders} = useTkOrder();

  useEffect(() => {
    setLoading(true);

    getLastOrders(state.start)
      .then((orders) => {
        if (orders) setOrders(orders);
        smoothScroll();
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [state]);

  return <>
    <TkHeadSEO title="Meus Pedidos | Plenobras"/>

    <TkHeader/>

    <div className="container m-t-1rem">
      <TkBreadcrumb
        list={[{
          url: tk_route_account,
          label: "Sua Conta",
        }, {
          url: tk_route_my_orders,
          label: "Meus Pedidos",
        }]}
      />

      <h1>Meus Pedidos</h1>

      <div className="row mt-5">
        <div className="col-6 col-sm-5 col-lg-2">
          <label>Selecione o período:</label>
        </div>
        <div className="col-5 col-sm-6 col-lg-2">
          <select
            value={state.start}
            onChange={(e) =>
              setState({
                start: parseFloat(e.target.value),
              })
            }
            className="form-control"
          >
            {months.map((m, idx) =>
              <option key={idx} value={m.start}>{m.label}</option>
            )}
          </select>
        </div>
        <div className="col-1 col-sm-1 col-lg-1">
          {loading && <TkSvgIcon
              className="rotate-1-seg TkMyOrdersView__loading"
              icon="sync-solid"
          />}
        </div>
      </div>
      <br/>

      {!loading && orders && orders.length === 0 && (
        <div>Não há pedidos para esse período selecionado</div>
      )}

      {!loading && orders && orders.map((o, idx) => <OrderArea key={idx} order={o}/>)}
    </div>

    <TkNewsLetter/>
    <TkFooter/>
  </>
};

export default TkMyOrdersView;
