import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import {ITkProductModel} from "../../models/product";
import TkSkeleton from "../../components/particles/TkSkeleton";
import TkProportionalImage from "../../components/particles/TkProportionalImage";
import {isBlank} from "../../utils/string-utils";

const NoPhoto = 'https://teky.s3.sa-east-1.amazonaws.com/no-image.svg';

const ProductImageArea: React.FC<{
    product: ITkProductModel | null | undefined,
    showSkeleton?: boolean
}> = ({product, showSkeleton = false}) => {
    const [skeletonHeight, setSkeletonHeight] = useState(10)
    const holderRef = useRef(null);
    const [currentImage, setCurrentImage] = useState<string>(null)

    useEffect(() => {
        if (holderRef && holderRef.current) {
            setSkeletonHeight(holderRef.current.offsetWidth - 9)
        }
    }, [holderRef])

    const getCurrentImage = useCallback(() => {
        if (!isBlank(currentImage)) return currentImage;

        return !!product.thumbnail ? product.thumbnail : NoPhoto
    }, [currentImage])

    if (showSkeleton) {
        return <div className='TkProductDetailView__image-area'>
            <div className='TkProductDetailView__image-thumbnails'>
                <TkSkeleton width='100%' height='50px'/>
            </div>
            <div ref={holderRef} className='TkProductDetailView__image-view'>
                <TkSkeleton width='100%'
                            height={skeletonHeight}
                            style={{margin: 0}}/>
            </div>
        </div>;
    }

    if (!product) return null;

    return <div className='TkProductDetailView__image-area'>
        <div className='TkProductDetailView__image-thumbnails'>
            {product.images && product.images.map((img, idx) => {
                return <span key={idx} onClick={() => setCurrentImage(img.url)}>
                    <TkProportionalImage url={img.url} plainB={NoPhoto}
                                         width={100}
                                         height={100}
                                         alt={product.name}/>
                </span>
            })}
        </div>
        <TkProportionalImage key={getCurrentImage()}
                             className='TkProductDetailView__image-view'
                             alt={product.name}
                             width={100}
                             height={100}
                             url={getCurrentImage()}
                             plainB={NoPhoto}/>
    </div>;
};

export default memo(ProductImageArea)
