import React, {useEffect, useState} from "react";
import TkHeader from "../../components/particles/TkHeader";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import {
  ITkClassModel,
  ITkDivisionModel,
  ITkFamilyModel,
  ITkGroupModel,
  ITkManufacturerModel
} from "../../models/product";
import "./style.scss";
import {useTkAppErrors, useTkManufacturerSitePage, useTkMedia, useTkProduct,} from "../../context/TkContext";
import SmallScreen from "./_smallScreen";
import MediumLargeScreen from "./_mediumLargeScreen";
import TkDivisionsTop from "../../components/product/TkDivisionsTop";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import {ProductListResult, ProductSearchCriteria,} from "../../context/TkProductContext";
import {useLocation, useParams} from "react-router-dom";
import {sendSearchTerm} from "../../utils/analytics-utils";
import {smoothScroll} from "../../utils/utils";
import useTkSearchView from "./index.hooks";
import {ITkManufacturerSitePageModel} from "../../models/manufacturerSitePage";
import {getContrastColor} from "../../utils/css-utils";
import {isBlank} from "../../utils/string-utils";

export const tk_route_search = "/pesquisar";

export const tk_route_manufacturer_page = '/parceiros/fabricantes/:slug';

const tk_route_hierarchy = (id?: string, keyName?: string, name?: string) => {
  const route = `${tk_route_search}`;

  if (id) {
    const params = new URLSearchParams();
    if ("division" === keyName) params.set(`${keyName}Id`, id + "");
    else params.set(`${keyName}Ids`, id + "");

    params.set(`${keyName}Name`, name);
    return `${route}?${params.toString()}`;
  }

  return route;
};

export class TkRouteSearchBuilder {
  private term?: string;
  private division?: ITkDivisionModel;
  private families?: ITkFamilyModel[] = [];
  private classes?: ITkClassModel[] = [];
  private groups?: ITkGroupModel[] = [];
  private manufacturers?: ITkManufacturerModel[] = [];

  setDivision(division: ITkDivisionModel) {
    this.division = division;
    return this;
  }

  addFamily(family: ITkFamilyModel) {
    this.families.push(family);
    return this;
  }

  addClass(clazz: ITkClassModel) {
    this.classes.push(clazz);
    return this;
  }

  addGroup(group: ITkGroupModel) {
    this.groups.push(group);
    return this;
  }

  addManufacturer(manufacturer: ITkManufacturerModel) {
    this.manufacturers.push(manufacturer);
    return this;
  }

  build() {
    const params = new URLSearchParams();
    if (this.term) params.set("term", this.term);
    if (this.division) params.set("divisionId", this.division._id);
    if (this.families.length > 0) {
      params.set("familiesIds", this.families.map((family) => family._id).join(","));
    }
    if (this.classes.length > 0) {
      params.set("classesIds", this.classes.map((clazz) => clazz._id).join(","));
    }
    if (this.groups.length > 0) {
      params.set("groupsIds", this.groups.map((group) => group._id).join(","));
    }
    if (this.manufacturers.length > 0) {
      params.set("manufacturersIds", this.manufacturers.map((manufacturer) => manufacturer._id).join(","));
    }
    return `${tk_route_search}?${params.toString()}`;
  }
}

export const tk_route_division = (division?: ITkDivisionModel) =>
  tk_route_hierarchy(
    division?._id,
    "division",
    division?.name || division?.label || ""
  );
export const tk_route_family = (family?: ITkFamilyModel) =>
  tk_route_hierarchy(family?._id, "families", family?.name || "");
export const tk_route_class = (clazz?: ITkClassModel) =>
  tk_route_hierarchy(clazz?._id, "classes", clazz?.name || "");
export const tk_route_term = (
  term: string,
  contextId?: string,
  nameContext?: string,
  label?: string
) => {
  if (contextId) {
    return `${tk_route_hierarchy(
      contextId,
      nameContext,
      label
    )}&term=${encodeURIComponent(term)}`;
  }
  return `${tk_route_search}?term=${encodeURIComponent(term)}`;
};

export const tk_route_manufacturer = (manufacturerId: string) => {
  return `${tk_route_search}?manufacturersIds=${manufacturerId}`;
};

interface TkSearchViewProps {
  isShowManufacturerPage?: boolean;
}

const TkSearchView: React.FC<TkSearchViewProps> = ({
    isShowManufacturerPage = false,
   }) => {
  const location = useLocation();
  const { isMediaXs } = useTkMedia();
  const { productSearch, getProductsOutdoor } = useTkProduct();
  const [hasNoResults, setHasNoResults] = useState(false);
  const { goToPageError } = useTkAppErrors();
  const [productsLoading, setProductsLoading] = useState(true);
  const [productResult, setProductResult] = useState<ProductListResult>({
    total: 0,
    items: [],
  });
  const { slug } = useParams<{ slug: string }>();
  const {getManufacturerSitePageBySlug} = useTkManufacturerSitePage();
  const [manufacturerSitePage, setManufacturerSitePage] = useState<ITkManufacturerSitePageModel | null>(null);

  const {makeTitle, makeKeywords} = useTkSearchView()

  useEffect(() => {
    setProductsLoading(true);
    setHasNoResults(false);

    const params = new URLSearchParams(location.search);

    //Tratamento de dePara dos antigos parâmetros. Pode ser removido posteriormente
    if (params.has('familyIds')) {
      params.set('familiesIds', params.get('familyIds'));
      params.delete('familyIds');
    }

    if (params.has('classIds')) {
      params.set('classesIds', params.get('classIds'));
      params.delete('classIds');
    }

    if (params.has('groupIds')) {
      params.set('groupsIds', params.get('groupIds'));
      params.delete('groupIds');
    }

    if (params.has('manufacturerIds')) {
      params.set('manufacturersIds', params.get('manufacturerIds'));
      params.delete('manufacturerIds');
    }

    const criteria: ProductSearchCriteria = [
      "term",
      "divisionId",
      "familiesIds",
      "classesIds",
      "groupsIds",
      "manufacturersIds",
      "stars",
      "from",
      "priceStart",
      "priceEnd",
      "nameOrder",
      "priceOrder",
      "showAvailableStock",
      "showPromotions"
    ].reduce((obj: any, key) => {
      if (params.has(key)) {
        if (/(manufacturers|families|classes|groups)Ids/g.test(key) || /(id|ids)/g.test(key)) {
          const tokens = params.get(key).split(",");
          obj[key] =
            tokens.length > 1
              ? tokens.reduce((obj, val) => {
                  obj.push(val);
                  return obj;
                }, [])
              : [tokens[0]];
        } else if (/(stars|from|pageSize)$/gi.test(key)) {
          obj[key] = parseInt(params.get(key));
        } else obj[key] = params.get(key);
      }
      return obj;
    }, {});

    setManufacturerSitePage(man => {
      if (man?.slug !== slug) return null;
      return man
    });

    smoothScroll();

    const timer = setTimeout(() => {
      if (isShowManufacturerPage) {
        getManufacturerSitePageBySlug(slug)
            .then((manufacturerSitePage) => {
              setManufacturerSitePage(manufacturerSitePage);
              productSearch({...criteria, manufacturersIds: [manufacturerSitePage.manufacturer._id]})
                  .then(productResult => {
                    setHasNoResults(productResult.total === 0);
                    setProductResult(productResult);
                  })
                  .catch(goToPageError)
                  .finally(() => setProductsLoading(false));
            })
            .catch(goToPageError)
      } else {
        productSearch(criteria)
            .then(productResult => {
              setHasNoResults(productResult.total === 0);
              setProductResult(productResult);
            })
            .catch(goToPageError)
            .finally(() => setProductsLoading(false));
      }
    }, 300)

    if (params.has("term")) sendSearchTerm(params.get("term"));

    return () => clearTimeout(timer);
  }, [location, setManufacturerSitePage]);

  useEffect(() => {
    if (hasNoResults) {
      setProductsLoading(true);
      const timer = setTimeout(() => {
        getProductsOutdoor("__vitrine__")
          .then(setProductResult)
          .catch(goToPageError)
          .finally(() => setProductsLoading(false));
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [hasNoResults]);

  const products = productsLoading ? [] : productResult?.items;

  let defaultDescription = "Pesquise produtos por divisões, marcas, famílias, classes, grupos, atributos, características e muito mais"
  if (isShowManufacturerPage && manufacturerSitePage) {
    defaultDescription = `Produtos da marca ${manufacturerSitePage.manufacturer.name} de várias divisões, famílias, classes, grupos, atributos, características e muito mais`
  }

  return <>
    <TkHeadSEO
        title={isShowManufacturerPage && manufacturerSitePage ? manufacturerSitePage.manufacturer.name : makeTitle()}
        description={defaultDescription}
        keywords={makeKeywords(...products)}
        image={isShowManufacturerPage && manufacturerSitePage ? manufacturerSitePage.logoUrl : undefined}
    />

      <TkHeader />
      <div style={manufacturerSitePage?.mainColor ? {backgroundColor: manufacturerSitePage.mainColor} : {}}>
        <TkDivisionsTop hideIcons={isShowManufacturerPage}/>
        <div className="TkWorldContainer">
            {!isMediaXs &&
              <div className="container-fluid m-t-1rem">
                <TkBreadcrumb
                  list={[
                    {
                      url: tk_route_search,
                      label: "Pesquisar",
                    },
                  ]}
                  className={manufacturerSitePage?.mainColor ? getContrastColor(manufacturerSitePage.mainColor) === "#FFFFFF" ? "c-w" : "c-ab" : "c-ab"}
              />
            </div>
        }

        {isMediaXs ?
            <SmallScreen
                products={products}
                hasNoResults={hasNoResults}
                total={productResult.total}
                loading={productsLoading}
                manufacturerSitePage={manufacturerSitePage}
            />
            :
            <MediumLargeScreen
                products={products}
                hasNoResults={hasNoResults}
                total={productResult.total}
                loading={productsLoading}
                manufacturerSitePage={manufacturerSitePage}
            />}
      </div>
    </div>
    <TkNewsLetter />
    <TkFooter />
  </>
};

export default TkSearchView;
