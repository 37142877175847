import React, {FC, memo, useEffect, useRef, useState} from "react";
import {BannerDeviceType, ITkManufacturerSitePageModel} from "../../models/manufacturerSitePage";
import _ManufacturerPagePopup, {ITkManufacturerPagePopup} from "./_manufacturerPagePopup";
import {isBlank} from "../../utils/string-utils";
import {getContrastColor} from "../../utils/css-utils";
import DeviceStorage from "../../utils/storage-utils";
import TkCarousel from "../../components/product/TkCarousel";
import {useTkMedia} from "../../context/TkContext";
import TkCardProductSlider from "../../components/product/TkCardProductSlider";

const BannerSlider:FC<{
    banners: string[]
}> = ({banners}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        if (isPaused) return;

        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [isPaused]);

    return <div className="TkSearchView__manufacturerSlider">
        {banners.map((banner, index) => (
            <div
                key={index}
                className={`TkSearchView__slide ${index === currentIndex ? "active" : ""}`}
                onMouseEnter={() => setIsPaused(true)}
                onMouseLeave={() => setIsPaused(false)}
                style={{ backgroundImage: `url(${banner})` }}
            />
        ))}
    </div>
}

const _manufacturerHeader: FC<{ manufacturerSitePage: ITkManufacturerSitePageModel }> = ({manufacturerSitePage}) => {
    const popupRef = useRef<ITkManufacturerPagePopup>()
    const manufacturerKeyTime = 'manufacturerPopupLastTimeOpen'+manufacturerSitePage.manufacturer._id;
    const {isMediaMd, isMediaSm, isMediaXs} = useTkMedia();
    const deviceType = isMediaMd || isMediaSm ? BannerDeviceType.MOBILE : BannerDeviceType.DESKTOP;

    const banners = manufacturerSitePage.banners
        .filter(b => b.deviceType === deviceType)
        .map(b => b.url);

    const convertToEmbedUrl = (url: string) => {
        if (!url) return null;
        const videoIdMatch = url.match(/(?:v=|\/embed\/|\/watch\?v=|youtu\.be\/)([^&]+)/);
        return videoIdMatch ? `https://www.youtube.com/embed/${videoIdMatch[1]}` : null;
    }

    const contrastColor = !isBlank(manufacturerSitePage.mainColor) ? getContrastColor(manufacturerSitePage?.mainColor) : 'initial';

    useEffect(() => {
        const lastTimeOpen = Number(DeviceStorage.get(manufacturerKeyTime));

        if (lastTimeOpen !== null && (Date.now() - lastTimeOpen) < 1000 * 60 * 10) {
            return;
        }

        if (manufacturerSitePage.popup && !isBlank(manufacturerSitePage.popup.imageUrl)) {
            setTimeout(() => {
                popupRef.current.open();
            }, (manufacturerSitePage?.popup?.delay || 0));
        }
    }, [popupRef, manufacturerSitePage]);

    const onClosePopup = () => {
        DeviceStorage.put(manufacturerKeyTime, Date.now().toString());
    }

    return <div>
        {banners.length > 0 ? <BannerSlider banners={banners}/> : null}
        <div style={{
            backgroundColor: !isBlank(manufacturerSitePage.mainColor) ? manufacturerSitePage.mainColor : 'initial',
            padding: '10px 10px 25px 10px'
        }}>
            <div className='m-t-15px row'>
                <div className='col-12 col-lg-6'>
                    <img src={manufacturerSitePage.logoUrl} style={{ width: '300px', height: 'auto', maxWidth: '80%' }} alt='logo' />
                    {manufacturerSitePage.presentationText ? (
                        <p className='m-t-30px' style={{color: contrastColor,}}>{manufacturerSitePage?.presentationText.split(/\n/).map(text => <p>{text}</p>)}</p>
                    ) : null}
                    {manufacturerSitePage.popup ?
                        <_ManufacturerPagePopup onClose={onClosePopup}
                            imageUrl={manufacturerSitePage?.popup?.imageUrl}
                            link={manufacturerSitePage?.popup?.link} ref={popupRef}/> 
                    : null}
                </div>
                <div className='col-12 col-lg-6'>
                    {manufacturerSitePage.videoUrl ?
                        <iframe style={!isMediaMd && !isMediaSm ? { width: '70%', aspectRatio: '16/9', border: 'none', margin: '0 auto', display: 'block' } : { width: '100%', aspectRatio: '16/9', border: '10px solid white', margin: '0 auto', display: 'block' }}
                                src={`${convertToEmbedUrl(manufacturerSitePage.videoUrl)}`} />
                        : null
                    }
                </div>
            </div>
            {(manufacturerSitePage?.highlightProducts || []).length > 0 ?
                <>
                    <h1 className='t-a-c m-t-15px' style={{color: contrastColor, fontSize: '1.3em'}}>Produtos em destaque</h1>
                    {isMediaXs || isMediaSm ?
                        <TkCardProductSlider products={manufacturerSitePage.highlightProducts || []} isLoadingProducts={false}
                                             style={manufacturerSitePage?.mainColor ? {backgroundColor: manufacturerSitePage.mainColor} : {}}
                        />
                        :
                        <TkCarousel.Root>
                            <div className={`TkSearchView__slider-container`}>
                                <TkCarousel.Product products={manufacturerSitePage.highlightProducts || []} isShowSkeleton={false}/>
                            </div>
                        </TkCarousel.Root>
                    }
                </>
                : null}
        </div>
    </div>
}

export default memo(_manufacturerHeader);
