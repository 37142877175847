import "./style.scss";
import React, {
    CSSProperties,
    FC,
    forwardRef,
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkHeader from "../../components/particles/TkHeader";
import TkDivisionsTop from "../../components/product/TkDivisionsTop";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import {
    useTkAppErrors,
    useTkCart,
    useTkMedia,
    useTkShipment,
    useTkUser,
    useTkWhatsAppBalloon,
} from "../../context/TkContext";
import {ITkProductQuantityModel} from "../../models/product";
import TkProportionalImage from "../../components/particles/TkProportionalImage";
import {formatMoney} from "../../utils/number-utils";
import {ITkCart} from "../../models/cart";
import TkSkeleton from "../../components/particles/TkSkeleton";
import {Link, useLocation} from "react-router-dom";
import {tk_route_root} from "../TkHomeView";
import {tk_route_checkout} from "../TkCheckoutView";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import {tk_route_product_detail} from "../TkProductDetailView";
import {isBlank} from "../../utils/string-utils";
import TkChangeDefaultAddress, {ITkModalChangeDefaultAddress,} from "../../components/user/TkChangeDefaultAddress";
import {tk_route_exchanges_and_devolution} from "../TkExchangesAndDevolutionView";
import {isIntersectionObserver, smoothScroll, vibrate,} from "../../utils/utils";
import {tk_route_manufacturer} from "../TkSearchView";
import TkModal, {TkModalActions, TkModalBody,} from "../../components/particles/TkModal";
import {transformGraphQLErrors} from "../../models/graphql";
import {FileTypes} from "../../models/file";
import {sendAnalyticsEvent} from "../../utils/analytics-utils";
import {downloadExcelFile, downloadPdfFile} from "../../utils/http-utils";
import TkShipmentArea, {TkShipmentAreaType,} from "../../components/shipment/TkShipmentArea";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import TkComplementCart from "../../components/cart/TkComplementCart";
import TkQuantitySelect from "../../components/cart/TkQuantitySelect";
import TkAvailabilityLabel from "../../components/cart/TkAvailabilityLabel";
import {tk_route_seller} from "../TkSellerInfoView";
import {calcDateShipment} from "../../utils/date-utils";
import DeviceStorage from "../../utils/storage-utils";
import TkCouponData from "../../components/cart/TkCouponData";

const NoPhoto = 'https://teky.s3.sa-east-1.amazonaws.com/no-image.svg';

export const tk_route_my_cart = "/carrinho-de-compras";


const CheckOutButton = forwardRef<HTMLDivElement, {
    bottomRef?: MutableRefObject<HTMLDivElement>
    itemQuantity: number
}>(({bottomRef, itemQuantity}, ref) => {
    const STICKY_DISTANCE = 105
    const [style, setStyle] = useState<CSSProperties>(bottomRef ? {
        position: 'sticky',
        top: STICKY_DISTANCE
    } : {})

    useEffect(() => {
        let intersectionObserver: IntersectionObserver;
        const isBottomRef = bottomRef && bottomRef.current

        if (isIntersectionObserver() && isBottomRef) {
            intersectionObserver = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        let top = STICKY_DISTANCE + (entry.isIntersecting ? -entry.intersectionRect.height : 0)
                        setStyle({
                            ...style,
                            top
                        })
                    });
                },
                {
                    root: null,
                    rootMargin: "0px",
                    threshold: Array.from({length: 101}, (_, i) => i / 100)
                }
            );
            intersectionObserver.observe(bottomRef.current);
        }

        return () => {
            if (intersectionObserver && intersectionObserver.unobserve) intersectionObserver.disconnect();
            intersectionObserver = null
        };

    }, [bottomRef]);


    return <div className='TkMyCartView__checkout-button' ref={ref} style={style}>
        <Link to={tk_route_checkout} className='btn btn-destak-gold btn-block'>Fechar pedido ({itemQuantity} ite{itemQuantity > 1 ? 'ns' : 'm'})</Link>
    </div>
})

const QuantityPopup: FC<{
    value: number
    onClose: () => void
    onChange: (value: number) => void
    step?: number
  }> = ({value, onClose, onChange, step = 1}) => {
    const options = ["0", `${step}`, `${2*step}`, `${3*step}`, `${4*step}`, `${5*step}`, `${6*step}`, `${7*step}`, `${8*step}`, `${9*step}`, `${10*step}`, `+${5*step}`, `+${10*step}`, `+${15*step}`, `+${20*step}`, `+${30*step}`]
  
    return <div className='TkQuantityPopup__overlay'>
        <div className='TkQuantityPopup'>
            <header>
                Quantidade
  
                <button type='button' onClick={onClose} aria-label="Fechar">
                    <TkSvgIcon icon='close'/>
                </button>
            </header>
            <ul>
                {options.map((val, idx) => {
                    const isPlus = val.startsWith('+')
                    const isZero = val === '0'
                    return <li key={idx}
                               className={value === (isPlus ? value + parseInt(val) : parseInt(val)) ? 'active' : ''}
                               onClick={() => {
                        if (isZero) onChange(0)
                        else if (isPlus) onChange(value + parseInt(val))
                        else onChange(parseInt(val))
                        onClose()
                    }}>{val} {isZero ? '(excluir)' : null}</li>
                })}
            </ul>
        </div>
    </div>
  }
  
  const QuantityEditor: FC<{
    value: number
    onChange: (value: number) => void
    minimumSaleQuantity?: number
    controlMultiplicity?: boolean
  }> = ({value, onChange, minimumSaleQuantity = 1, controlMultiplicity = false}) => {
    const {setShowIcon} = useTkWhatsAppBalloon()
    const [isVisible, setVisible] = useState(false)
    const step = controlMultiplicity ? minimumSaleQuantity : 1 
  
    useEffect(() => {
        document.body.classList[isVisible ? "add" : "remove"]("o-h");
    }, [isVisible]);
  
    return <>
  
        {isVisible && <QuantityPopup value={value} onClose={() => setVisible(false)} onChange={onChange} step={step}/>}
  
        <span className='TkQuantityEditor'>
            <button type='button' className='TkQuantityEditor__left-button' onClick={() => onChange(value - step)}>
                <TkSvgIcon icon={value <= 1*step ? 'trash-alt' : 'minus-solid'}/>
            </button>
            <span className='TkQuantityEditor__quantity' onClick={() => {
                setVisible(!isVisible)
                setShowIcon(false)
            }}>{value}</span>
            <button type='button' className='TkQuantityEditor__right-button' onClick={() => onChange(value + step)}>
                <TkSvgIcon icon='plus-solid'/>
            </button>
        </span>
    </>
  }

const WithdrawArea: FC<{
    cartItem: ITkProductQuantityModel
}> = ({cartItem}) => {
    const {updateCart} = useTkCart();
    const {shipmentValue, isUpdatingCartItem, isRSShipment} = useTkShipment()
    const isBackorderProduct= cartItem.product.leadTime > 0 && (cartItem.product.stock == 0 || (cartItem.quantity > cartItem.product.stock))
    const [isWithdraw, setWithdraw] = useState(cartItem.isWithdraw)
    const [isUpdating, setUpdating] = useState(false)
    const [limitedDelivery, setLimitedDelivery] = useState(false)
    const lastPostalCode = DeviceStorage.getPostalCode || "";

    const isLimitedDelivery = useEffect(() => {
        setLimitedDelivery(cartItem.product.isRestrictedDelivery && !isRSShipment)
    }, [cartItem.product, setLimitedDelivery, isRSShipment])

    const changeWithdraw = useCallback(async (isWithdrawNew: boolean) => {
        setUpdating(true)
        try {
            setWithdraw(isWithdrawNew)
            await updateCart(cartItem.product, cartItem.quantity, isWithdrawNew);
        } catch (e) {
            setWithdraw(!isWithdrawNew)
            console.error('Falha ao alterar retirada', e)
        } finally {
            setUpdating(false)
        }
    }, [setUpdating, setWithdraw, cartItem, updateCart]);

    const withdrawHour = useMemo(() => {
        if (!shipmentValue || isBackorderProduct || !isWithdraw) return null

        const hour = new Date().getHours()
        const minutes = (new Date().getMinutes() < 10) ? `0${new Date().getMinutes()}` : new Date().getMinutes()
        if(hour > 8 && hour <= 13 ) return `${hour + 4}h ${minutes}min`
        if(hour > 13 && hour < 18) return `${4 - (18 - hour) + 8}h ${minutes}min`
        return `12h 00min`

    }, [shipmentValue])

    const shipmentHandler = useMemo(() => {
        if (!shipmentValue) return null

        if (isBackorderProduct && shipmentValue.backorderShipment) {
            const {deadlineDays, withdrawDeadline} = shipmentValue.backorderShipment
            if (!isWithdraw && deadlineDays > 0) return `Entrega: ${calcDateShipment(deadlineDays)}`
            if (isWithdraw && withdrawDeadline > 0) return `Retirada: ${calcDateShipment(withdrawDeadline)}`
        } else {
            const {shipmentDeadLine, withdrawDeadLine} = shipmentValue
            if (!isWithdraw && shipmentDeadLine > 0) return `Entrega: ${calcDateShipment(shipmentDeadLine)}`
            if (isWithdraw && withdrawDeadLine >= 0) return `${calcDateShipment(withdrawDeadLine)}`
        }

        return 'Entrega: --/--'
    }, [shipmentValue, isBackorderProduct, isWithdraw, lastPostalCode])

    return <div className='TkMyCartView__withdraw'>
        <label>
            <input type='checkbox'
                   onChange={() => changeWithdraw(!isWithdraw)}
                   checked={isWithdraw}
                   disabled={limitedDelivery}/>&nbsp;
            Retirar na loja&nbsp;&nbsp;
        </label>
        <div key={`${cartItem.product._id}${shipmentHandler ? '' : '_'}`}>
            {isUpdating || isUpdatingCartItem(cartItem.product._id) || shipmentHandler === null ?                
                <>
                    <TkSvgIcon icon='sync-solid' className='rotate-1-seg m-r-1em'/>
                    Calculando...
                </>
                :
                <span className='TkWithdrawCard__shipmentDate'>{shipmentHandler} {withdrawHour && cartItem.isWithdraw ? <>às {withdrawHour}</> : ''}</span>            
            }
        </div>
    </div>
}

const InfoSmall: FC<{
    cart?: ITkCart | null;
    showSkeleton?: boolean;
    download(type: FileTypes): void;
    isOnTop?: boolean;
}> = ({cart, showSkeleton = false, download, isOnTop = true}) => {
    const {updatingCart, calculateSubTotal} = useTkCart();
    const {shipmentCost} = useTkShipment();
    const {userAuth, isAuth, me} = useTkUser();
    const [loadedUser, setLoadedUser] = useState(false);
    const [showCouponInput, setShowCouponInput] = useState(false);

    useEffect(() => {
        if (isAuth() && !userAuth.user.defaultAddress) {
            me().then(() => setLoadedUser(true))
        } else {
            setLoadedUser(true)
        }
    }, []);

    if (showSkeleton || !cart)
        return <div
            className={`t-a-c TkMyCartView__info TkMyCartView__info--border-${
                !isOnTop ? "top" : "bottom"
            }`}
        >
            <TkSkeleton width="50%" height="25px"/>
            <br/>
            <TkSkeleton width="90%" height="35px"/>
        </div>

    return <article
        className='TkMyCartView__info'
    >

        {!isOnTop && <article className="TkMyCartView__shipmentArea">
                <div className="t-a-c"><b>Consultar entrega e frete</b></div>
                {loadedUser && <TkShipmentArea
                    type={TkShipmentAreaType.cart_detail}
                    isShowWithdraw={false}
                />}
            </article>
        }

        {!isOnTop && <>
            <div className="t-a-l TkMyCartView__links-download-mobile m-t-10px">
                <Link to="#download-excel" onClick={() => download(FileTypes.XLSX)}>
                    <TkSvgIcon icon='print-icon'/><span className="excel">Excel</span>
                </Link>

                <Link to="#download-pdf" onClick={() => download(FileTypes.PDF)}>
                    <TkSvgIcon icon='print-icon'/><span className="pdf">PDF</span>
                </Link>
            </div>
            {!showCouponInput ? <div className="TkMyCartView__coupon">
                <p className="" onClick={() => setShowCouponInput(true)}>Adicionar Cupom</p>
            </div> : 
            <div className="TkMyCartView__coupon__border">
                <TkCouponData addedCoupon={cart?.coupon}/>
            </div>}

            {!cart.coupon ?
                <h3 style={{borderBottom: 'none', paddingBottom: 0, paddingTop: 0}}>
                Subtotal ({cart.items.length} ite{cart.items.length > 1 ? "ns" : "m"}):&nbsp;
                <span>{updatingCart && !loadedUser
                    ? "Atualizando..."
                    : formatMoney(calculateSubTotal(shipmentCost.cartShipment))}
                </span>
                </h3> : 
                <>
                    <h3 style={{borderBottom: 'none', paddingBottom: 0, paddingTop: 0, fontWeight: "normal"}}>
                    Subtotal ({cart.items.length} ite{cart.items.length > 1 ? "ns" : "m"}):&nbsp;
                    <span>{updatingCart && !loadedUser
                        ? "Atualizando..."
                        : formatMoney(calculateSubTotal(shipmentCost.cartShipment))}
                    </span>
                    </h3>
                    <h3 style={{paddingTop: 0}}>
                    Total (com desconto):&nbsp;
                        <span>{updatingCart && !loadedUser
                            ? "Atualizando..."
                            : formatMoney(calculateSubTotal(shipmentCost.cartShipment)-cart.couponSavings)}
                            </span>
                    </h3>
                </>
            }
        </>
        }

    </article>

};

const Info: FC<{
    cart?: ITkCart | null;
    download(type: FileTypes): void;
    showSkeleton?: boolean;
}> = ({cart, download, showSkeleton = false}) => {
    const {userAuth, isAuth, me} = useTkUser();
    const {updatingCart, calculateSubTotal,} = useTkCart();
    const {shipmentCost} = useTkShipment();
    const modalChangeDefaultAddressRef = useRef<ITkModalChangeDefaultAddress>();
    const [loadedUser, setLoadedUser] = useState(false);
    const [showCouponInput, setShowCouponInput] = useState(false);

    useEffect(() => {
        if (isAuth() && !userAuth.user.defaultAddress) {
            me().then(() => setLoadedUser(true))
        } else {
            setLoadedUser(true)
        }
    }, []);

    if (showSkeleton || !cart)
        return <div>
            <div className="TkMyCartView__info">
                <TkSkeleton width="50%" height="25px"/>
                <br/>
                <TkSkeleton width="60%" height="20px"/>
                <br/>
                <br/>
                <div className="t-a-c">
                    <TkSkeleton width="90%" height="35px"/>
                </div>
            </div>
            <TkShipmentArea
                type={TkShipmentAreaType.cart_detail}
                isShowInBalloon={true}
                showSkeleton={true}
            />
        </div>

    return <>
        <TkChangeDefaultAddress ref={modalChangeDefaultAddressRef}/>
        <div>
            <div className="TkMyCartView__sticky">
                <div className="TkMyCartView__info">
                    {!cart.coupon ?
                    <h3 style={{display: 'flex', justifyContent: 'space-between'}}>
                        Subtotal:&nbsp;
                        <span style={{textAlign: 'right'}}>
                        {updatingCart
                            ? "Atualizando..."
                            : formatMoney(calculateSubTotal(shipmentCost.cartShipment))}
                      </span>
                    </h3>
                    :
                        <>
                            <h3 style={{display: 'flex', justifyContent: 'space-between', fontWeight:"normal"}}>
                                Subtotal:&nbsp;
                                <span style={{textAlign: 'right'}}>
                                {updatingCart
                                    ? "Atualizando..."
                                    : formatMoney(calculateSubTotal(shipmentCost.cartShipment))}
                            </span>
                            </h3>
                            <h3 style={{display: 'flex', justifyContent: 'space-between'}}>
                                Total (com desconto):&nbsp;
                                <span style={{textAlign: 'right'}}>
                                {updatingCart
                                    ? "Atualizando..."
                                    : formatMoney(calculateSubTotal(shipmentCost.cartShipment)-cart.couponSavings)}
                              </span>
                            </h3>
                        </>
                    }

                    <div className="m-t-20px">
                        <Link
                            to={tk_route_checkout}
                            className="btn btn-destak-gold btn-sm btn-block ellipsis"
                        >
                            Confirmar pedido
                        </Link>
                        <Link
                            to={tk_route_root}
                            className="btn btn-ice-gray btn-sm btn-block m-t-10px"
                        >
                            Continuar comprando
                        </Link>
                    </div>
                    <div className="t-a-l TkMyCartView__links-download m-t-10px">
                        <Link to="#download-excel" onClick={() => download(FileTypes.XLSX)}>
                            <TkSvgIcon icon='print-icon'/><span className="excel">Excel</span>
                        </Link>

                        <Link to="#download-pdf" onClick={() => download(FileTypes.PDF)}>
                            <TkSvgIcon icon='print-icon'/><span className="pdf">PDF</span>
                        </Link>
                    </div>
                    <br/>
                    <p className="TkMyCartView__policy">
                        Consulte nossa política de&nbsp;
                        <Link
                            to={tk_route_exchanges_and_devolution}
                            title="Veja nossa política de Trocas e Devoluções"
                        >
                            Trocas e Devoluções
                        </Link>
                    </p>
                </div>
                {!showCouponInput && !cart.coupon ? <div className="TkMyCartView__coupon">
                    <p onClick={() => setShowCouponInput(true)}>Adicionar Cupom</p>
                </div> : 
                <div className="TkMyCartView__coupon__border">
                    <TkCouponData addedCoupon={cart?.coupon}/>
                </div>}
                {loadedUser && <TkShipmentArea
                    type={TkShipmentAreaType.cart_detail}
                    isShowInBalloon={true}
                    isShowWithdraw={false}
                />}
            </div>
        </div>
    </>
};

const CardSmall: FC<{
    item: ITkProductQuantityModel;
    showErrorValidation(message: string): void;
    showSkeleton?: boolean;
}> = ({item, showErrorValidation, showSkeleton = false}) => {
    const {updateCart, isUpdatingCartItem, removeFromCart, } = useTkCart();
    const {shipmentValue} = useTkShipment()


    const updateQuantity = useCallback(async (quantity: any) => {
            if (item.product) {
                try {
                    await updateCart(item.product, parseInt(quantity), item.isWithdraw);
                } catch (error) {
                    vibrate();
                    //@ts-ignore
                    const msgs = transformGraphQLErrors(error);
                    showErrorValidation(
                        msgs
                            ? msgs.map((m) => m.message).join("\n")
                            : "Falhou ao tentar adicionar ao carrinho. Tente novamente."
                    );
                }
            }
        },
        [item]
    );

    const remove = useCallback(async () => {
        if (item.product) await removeFromCart(item.product);
    }, [item, removeFromCart]);

    if (showSkeleton || !item)
        return <div>
            <br/>
            <TkSkeleton width="100%" height="20px"/>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridGap: 5,
                }}
            >
                <TkSkeleton width="150px" height="150px"/>
                <div>
                    <TkSkeleton width="100px" height="20px"/>
                    <TkSkeleton width="90px" height="20px"/>
                    <br/>
                    <TkSkeleton width="80px" height="20px"/>
                </div>
            </div>
            <br/>
            <div>
                <TkSkeleton width="100px" height="40px"/>
            </div>
        </div>


    return <article className={`TkMyCartView__card ${isUpdatingCartItem(item.product) ? 'TkMyCartView__card-updating' : ''}`}>

        {isUpdatingCartItem(item.product) ? <span>
            <TkSvgIcon icon='sync-solid' className='rotate-1-seg'/>
        </span> : null}

        <h2 className="TkMyCartView__title__card">
            <Link
                to={tk_route_product_detail(item.product)}
                title="Ver detalhes do produto"
            >
                {item.product?.name}
            </Link>
        </h2>
        <Link
            key={item.product._id}
            to={tk_route_product_detail(item.product)}
            title="Ver detalhes do produto"
        >
            <TkProportionalImage
                className="TkMyCartView__img"
                url={item.product?.thumbnail || NoPhoto}
                plainB={NoPhoto}
            />
        </Link>
        <div className="TkMyCartView__details">
            {item.product?.manufacturerReference && (
                <p className="TkMyCartView__reference">
                    {item.product?.manufacturerReference}
                </p>
            )}
            <p className="TkMyCartView__brand">
                por&nbsp;
                <Link
                    to={tk_route_manufacturer(item.product?.manufacturerId)}
                    onClick={(e) => e.stopPropagation()}
                >
                    {item.product?.manufacturerName}
                </Link>
            </p>
            <p className="TkMyCartView__price">
                <span>{formatMoney(item.price)}</span>
            </p>
            <p className="TkMyCartView__supplier">
                Vendido por <Link to={tk_route_seller}>Plenobras</Link>
            </p>
            <TkAvailabilityLabel item={item}/>
        </div>

        <div style={{
            gridArea: 'shp-rgt',
        }}>
            {(item?.product.stock || item?.product.leadTime) && item?.product.isWithdraw ?
                <WithdrawArea cartItem={item}/> :
                `Entrega: ${shipmentValue && calcDateShipment(shipmentValue.shipmentDeadLine)}`
            }
        </div>

        <div className="TkMyCartView__actions-left">
            <QuantityEditor value={item.quantity}
                            onChange={(qtd) => {
                                if (qtd < 1) remove();
                                else updateQuantity(qtd)
                            }}
                            minimumSaleQuantity={item.product.minimumSaleQuantity}
                            controlMultiplicity={item.product.controlMultiplicity}/>
        </div>
        <div style={{
            gridArea: 'act-rgt',
            justifySelf: 'start',
        }}>
            <button className='button' style={{
                marginBottom: 0,
                marginTop: 10,
            }} onClick={remove}>
                Excluir
            </button>
        </div>

    </article>
};

const Card: FC<{
    item: ITkProductQuantityModel;
    showErrorValidation(message: string): void;
    showSkeleton?: boolean;
}> = ({item, showErrorValidation, showSkeleton = false}) => {

    const {updateCart, updatingCart, removeFromCart} = useTkCart();
    const {shipmentValue} = useTkShipment()


    const updateQuantity = useCallback(async (quantity: any) => {
            if (item.product) {
                try {
                    await updateCart(item.product, parseInt(quantity), item.isWithdraw);
                } catch (error) {
                    console.error('Falha ao alterar quantidade', error)
                    vibrate();
                    // @ts-ignore
                    const msgs = transformGraphQLErrors(error);
                    showErrorValidation(
                        msgs
                            ? msgs.map((m) => m.message).join("\n")
                            : "Falhou ao tentar adicionar ao carrinho. Tente novamente."
                    );
                }
            }
        },
        [updateCart]
    );

    const remove = useCallback(async (item) => {
        if (item.product) await removeFromCart(item.product);
    }, []);

  if (showSkeleton)
    return <div className="TkMyCartView__card">
      <TkSkeleton width="200px" height="200px"/>
      <div className="TkMyCartView__details">
        <TkSkeleton width="90%" height="25px"/>
        <br/>
        <TkSkeleton width="30%" height="15px"/>
        <br/>
        <TkSkeleton width="25%" height="15px"/>
        <div className="TkMyCartView__actions">
          <TkSkeleton width="25%" height="25px"/>
          <TkSkeleton width="25%" height="25px"/>
        </div>
      </div>
    </div>

    return <article className="TkMyCartView__card">
        <Link
            key={item.product._id}
            to={tk_route_product_detail(item.product)}
            title="Ver detalhes do produto"
        >
            <TkProportionalImage
                className="TkMyCartView__img"
                url={item.product?.thumbnail || NoPhoto}
                plainB={NoPhoto}
            />
        </Link>
        <div className="TkMyCartView__details">
            <h2 className="TkMyCartView__title__card">
                <Link
                    to={tk_route_product_detail(item.product)}
                    title="Ver detalhes do produto"
                >
                    {item.product?.name}
                </Link>
            </h2>
            {item.product?.manufacturerReference && (
                <p className="TkMyCartView__reference">
                    {item.product?.manufacturerReference}
                </p>
            )}
            <p className="TkMyCartView__brand">
                por&nbsp;
                <Link
                    to={tk_route_manufacturer(item.product?.manufacturerId)}
                    onClick={(e) => e.stopPropagation()}
                >
                    {item.product?.manufacturerName}
                </Link>
            </p>

            <TkAvailabilityLabel item={item}/>

            <div className="TkMyCartView__actions">
                <TkQuantitySelect
                    onChange={updateQuantity}
                    product={item.product}
                    disabled={updatingCart}
                    initialQuantity={item.quantity}
                    key={item.product._id}
                />
                <a href="#excluir" onClick={() => remove(item)} className="m-l-1rem">
                    Excluir
                </a>
            </div>

            {(item?.product.stock || item?.product.leadTime) && item?.product.isWithdraw ?
                <WithdrawArea cartItem={item}/> :
                `Entrega: ${shipmentValue && calcDateShipment(shipmentValue.shipmentDeadLine)}`
            }

        </div>
        <p className="TkMyCartView__price">
            <span>{formatMoney(item.price)}</span>&nbsp;&nbsp;por&nbsp;
            <span>
            {
                item?.product.unitCommercialName
              ? item?.product.unitCommercialName.toLowerCase()
              : "unidade"
            }
            </span>
        </p>
    </article>
};

const TkMyCartView: FC = () => {
    const location = useLocation();
    const {currentCart, getCart, copyListToCart, getCartAsFile} = useTkCart();
    const {setShowIcon} = useTkWhatsAppBalloon()
    const {isMediaXs, isMediaSm} = useTkMedia();
    const [refresh, setRefresh] = useState(!(currentCart && currentCart.totalQuantity));
    const [showAlertValidation, setShowAlertValidation] = useState<string>(null);
    const {goToPageError} = useTkAppErrors();
    const [downloading, setDownloading] = useState<FileTypes>(null);
    const checkOutButtonRef = useRef<HTMLDivElement>()


    useEffect(() => {
        const p = new URLSearchParams(location.search);
        const listId = p.get("fromList");

        smoothScroll();

        setRefresh(refresh);

        setShowIcon(false)

        const timer = setTimeout(async () => {
            if (!isBlank(listId)) await copyListToCart(listId);
            else await getCart();

            setRefresh(false);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    async function download(fileType: FileTypes) {
        if (downloading !== null) return;

        sendAnalyticsEvent("Download Arquivo", "Carrinho de Compras", FileTypes[fileType]);
        setDownloading(fileType);
        try {
            const {
                data: {base64, extension, fileName},
            } = await getCartAsFile(fileType);
            if (fileType === FileTypes.PDF)
                await downloadPdfFile(base64, fileName, extension);
            else if (fileType === FileTypes.XLSX)
                await downloadExcelFile(base64, fileName, extension);
        } catch (e) {
            goToPageError(e);
        } finally {
            setDownloading(null);
        }
    }

    const items = refresh ? [null] : currentCart?.items || [];

    return <div className="TkMyCartView">
        <TkHeadSEO title="Carrinho de Compras | Teky"/>

        <TkHeader/>
        <TkDivisionsTop/>

        {(isMediaXs || isMediaSm) && <CheckOutButton bottomRef={checkOutButtonRef} itemQuantity={items?.length}/>}

        <div className="container">
            <TkBreadcrumb
                list={[
                    {
                        url: tk_route_my_cart,
                        label: "Carrinho de Compras",
                    },
                ]}
            />

            <div className="TkMyCartView__title-container">
                <h1 className="TkMyCartView__title">Carrinho de Compras</h1>
                {(!isMediaSm && !isMediaXs) ? <span className="TkMyCartView__title-price">Preço</span> : null}
            </div>

            {items && items.length > 0 ? <>
                <div className="TkMyCartView__container">
                    {(isMediaXs || isMediaSm) && <InfoSmall
                            cart={currentCart}
                            download={download}
                            showSkeleton={refresh}
                        />
                    }

                    <div>
                        {items
                            .sort((a, b) => (a?.displayOrder ?? 0) - (b?.displayOrder ?? 0))
                            .map((item, index) => {
                                if (isMediaXs || isMediaSm) {
                                    return <CardSmall
                                        item={item}
                                        key={item ? `${item.product._id}_${index}` : index}
                                        showErrorValidation={setShowAlertValidation}
                                        showSkeleton={refresh}
                                    />
                                }

                                return <Card
                                    item={item}
                                    key={item ? `${item.product._id}_${index}` : index}
                                    showErrorValidation={setShowAlertValidation}
                                    showSkeleton={refresh}
                                />
                            })}
                    </div>
                    {!isMediaXs && !isMediaSm ? (
                        <Info
                            cart={currentCart}
                            download={download}
                            showSkeleton={refresh}
                        />
                    ) : <InfoSmall
                            cart={currentCart}
                            download={download}
                            showSkeleton={refresh}
                            isOnTop={false}
                        />
                    }
                </div>
            </> : !refresh && <div className="TkMyCartView__no-items">
                Não há ítens no carrinho&nbsp;
                <Link to={tk_route_root}>Continuar comprando</Link>
            </div>
            }
        </div>

        {items?.length > 0 && (isMediaXs || isMediaSm) && <CheckOutButton ref={checkOutButtonRef} itemQuantity={items?.length}/>}

        <TkComplementCart/>

        <TkNewsLetter/>

        <TkFooter/>

        <TkModal
            show={showAlertValidation !== null}
            close={() => setShowAlertValidation(null)}
            title="Adicionar ao Carrinho"
            size="sm"
        >
            <TkModalBody>{showAlertValidation}</TkModalBody>

            <TkModalActions>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowAlertValidation(null)}
                >
                    Fechar
                </button>
            </TkModalActions>
        </TkModal>
    </div>
};
export default TkMyCartView;
