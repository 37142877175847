import React, {CSSProperties, useCallback, useEffect, useState} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useTkProduct} from "../../context/TkContext";
import TkHorizontalCardProduct from "../../components/product/TkHorizontalCardProduct";
import TkPaginator from "../../components/particles/TkPaginator";
import {ITkProductModel} from "../../models/product";
import {tk_route_search} from "./index";
import {isBlank} from "../../utils/string-utils";
import TkChip from "../../components/particles/TkChip";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import TkHasNoResults from "./_hasNoResults";
import {ClassifiersType} from "../../context/TkProductContext";
import {useClassifierFilter} from "./_classifiersFilterStore";
import AutocompleteClassifier, {ClassifierType} from "./_autocompleteClassifiers";
import {BooleanQuerySearch} from "./BooleanQuerySearch";
import {ITkManufacturerSitePageModel} from "../../models/manufacturerSitePage";
import {getContrastColor} from "../../utils/css-utils";
import ManufacturerHeader from "./_manufacturerHeader";

const Filters: React.FC<{
    isShowManufacturerPage?: boolean
}> = ({isShowManufacturerPage = false}) => {
    const history = useHistory();
    const location = useLocation();
    const {loadClassifiers} = useTkProduct();

    const [selectedDivision] = useState(null);
    const [priceStartValue, setPriceStartValue] = useState('');
    const [priceEndValue, setPriceEndValue] = useState('');
    const [showAvailableStock, setShowAvailableStock] = useState(false);
    const [showPromotions, setShowPromotions] = useState(false);
    const {selectedFamiliesIds, selectedClassesIds} = useClassifierFilter()

    useEffect(() => {
        const param = new URLSearchParams(location.search);

        setShowPromotions(param.get("showPromotions") === 'true')
        setShowAvailableStock(param.get("showAvailableStock") === 'true')

        const priceStart = param.get("priceStart")
        const priceEnd = param.get("priceEnd")

        setPriceStartValue(priceStart || '')
        setPriceEndValue(priceEnd || '')

    }, [location.search, setShowPromotions, setShowAvailableStock, setPriceStartValue, setPriceEndValue])

    const changePromoAndStock = useCallback((field: BooleanQuerySearch) => {
        const param = new URLSearchParams(location.search);

        if (field === BooleanQuerySearch.PROMO) param.set("showPromotions", (param.get("showPromotions") !== 'true') + '');
        else if (field === BooleanQuerySearch.STOCK) param.set("showAvailableStock", (param.get("showAvailableStock") !== 'true') + '');

        history.push(`${location.pathname}?${param.toString()}`);
    }, [location])

    const linksPrice = [{
        to: urlPrice(0, 100),
        label: 'Até R$ 100,00'
    }, {
        to: urlPrice(100, 300),
        label: 'R$ 100,00 a R$ 300,00'
    }, {
        to: urlPrice(300, 1000),
        label: 'R$ 300,00 a R$ 1.000,00'
    }, {
        to: urlPrice(1000, 0),
        label: 'Acima de R$ 1.000,00'
    }]

    useEffect(() => {
        loadClassifiers(ClassifiersType.DIVISIONS, ClassifiersType.MANUFACTURERS, ClassifiersType.FAMILIES, ClassifiersType.CLASSES, ClassifiersType.GROUPS)

        const params = new URLSearchParams(location.search);

        setShowPromotions(params.get('showPromotions') === "true")
        setShowAvailableStock(params.get('showAvailableStock') === "true")
    }, [setShowPromotions, setShowAvailableStock]);

    function removeDivision() {
        const params = new URLSearchParams(location.search);
        params.delete('divisionId');
        params.delete('divisionName');

        history.push(`${location.pathname}?${params.toString()}`);
    }

    function urlPrice(start: number, finish = 0) {
        const params = new URLSearchParams(location.search);
        params.set('priceStart', `${start}`);
        params.delete('priceEnd');
        if (finish > 0) params.set('priceEnd', `${finish}`);
        return params;
    }

    function filterByPrice() {
        const params = new URLSearchParams(location.search);
        params.delete('priceStart');
        params.delete('priceEnd');

        if (!isBlank(priceStartValue)) params.set('priceStart', priceStartValue);
        if (!isBlank(priceEndValue)) params.set('priceEnd', priceEndValue);

        history.push(`${location.pathname}?${params.toString()}`);
    }

    return <>
        {selectedDivision && <>
            <h2 className='TkSearchView__filter-title'>Mostrando apenas</h2>
            <TkChip label={selectedDivision.name} remove={removeDivision} icon={selectedDivision.icon}/>
        </>}

        <h2 className='TkSearchView__filter-title m-t-30px'>Disponibilidade</h2>
        <label>
            <input type='checkbox'
                   onChange={() => changePromoAndStock(BooleanQuerySearch.STOCK)}
                   name='showAvailableStock'
                   checked={showAvailableStock}/> Estoque disponível
        </label>

        <label>
            <input type='checkbox'
                   onChange={() => changePromoAndStock(BooleanQuerySearch.PROMO)}
                   name='showPromotions'
                   checked={showPromotions}/> Produtos em Promoção
        </label>

        {!isShowManufacturerPage ? <AutocompleteClassifier type={ClassifierType.MANUFACTURER}/> : null}
        <AutocompleteClassifier type={ClassifierType.FAMILY}/>
        <AutocompleteClassifier type={ClassifierType.CLASS} isVisible={selectedFamiliesIds.length > 0}/>
        <AutocompleteClassifier type={ClassifierType.GROUP} isVisible={selectedClassesIds.length > 0}/>


        <h2 className='TkSearchView__filter-title m-t-30px'>Faixa de preço</h2>
        <ul className='TkSearchView__filter-options'>
            {linksPrice.map((l, idx) => {
                const currentParams = new URLSearchParams(location.search);
                const params = new URLSearchParams(l.to);
                const start = currentParams.get('priceStart');
                const end = currentParams.get('priceEnd');

                let active = start === params.get('priceStart') && end === params.get('priceEnd');

                return <li key={idx}>
                    <Link to={`${location.pathname}?${l.to.toString()}`}
                          className='TkSearchView__link-price'>{active ?
                        <TkSvgIcon icon='check-solid'/> : ''}{l.label}</Link>
                </li>
            })}
        </ul>

        <div className='TkSearchView__price-container'>
            <input type='number'
                   onKeyPress={e => {
                       if (!/[0-9]/.test(e.key)) e.preventDefault();
                   }}
                   onChange={e => setPriceStartValue(e.target.value)}
                   min={1}
                   value={priceStartValue}
                   className='form-control form-control-sm'
                   placeholder='R$ De'/>
            <input type='number'
                   onKeyPress={e => {
                       if (!/[0-9]/.test(e.key)) e.preventDefault();
                   }}
                   onChange={e => setPriceEndValue(e.target.value)}
                   value={priceEndValue}
                   min={1}
                   className='form-control form-control-sm'
                   placeholder='R$ Até'/>
            <button type='button' onClick={filterByPrice} className='btn btn-sm btn-ice-gray'>Ir</button>
        </div>

        <button type='reset'
                className='btn btn-sm btn-ice-gray m-t-30px'
                onClick={() => history.push(tk_route_search)}
                title='Limpar filtros e mostrar todos produtos'>&times; Limpar filtros
        </button>
    </>
}

const MediumLargeScreen: React.FC<{
    loading: boolean
    hasNoResults: boolean
    total: number
    products: ITkProductModel[]
    manufacturerSitePage?: ITkManufacturerSitePageModel;
}> = ({loading = true, hasNoResults = false, total = 0, products = [], manufacturerSitePage}) => {
    const location = useLocation();
    const history = useHistory();
    const [ordering, setOrdering] = useState('');
    const params = new URLSearchParams(location.search);
    const styleContainer:CSSProperties = {};

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const priceOrder = params.get('priceOrder');
        const nameOrder = params.get('nameOrder');

        if (!isBlank(priceOrder)) {
            setOrdering(`price${JSON.parse(priceOrder) ? 'Asc' : 'Desc'}`);
        } else if (!isBlank(nameOrder)) {
            setOrdering(`name${JSON.parse(nameOrder) ? 'Asc' : 'Desc'}`);
        }
    }, [location]);

    const orderingChange = useCallback((e: any) => {
        const param = new URLSearchParams(location.search);
        param.delete('priceOrder');
        param.delete('nameOrder');

        const val = e.target.value;
        if (/price/gi.test(val)) param.set('priceOrder', /Asc$/gi.test(e.target.value) + '');
        else if (/name/gi.test(val)) param.set('nameOrder', 'true');

        history.push(`${location.pathname}?${param.toString()}`);
    }, [location]);

    let range = '';
    let searchTerm = '';

    if (params.has('from')) {
        const from = parseInt(params.get('from'));
        range = `${from <= 0 ? from + 1 : from} - ${from + 12 < total ? from + 12 : total }`
    }

    const term = params.get('term');
    const divisionName = params.get('divisionName');
    if (hasNoResults) {
        if (!isBlank(term)) searchTerm = `para <span class='c-p-c'>"${term}"</span> pesquisado`;
        if (!isBlank(divisionName)) {
            searchTerm += ` na divisão <b>${divisionName}</b>`
        }
    }

    return <div className='container-fluid'>
            <div className="row" style={styleContainer}>
                <div className="col-md-10">
                    {hasNoResults ? <h1 className='TkSearchView__title-resume' style={manufacturerSitePage?.mainColor ? {color: getContrastColor(manufacturerSitePage.mainColor)} : {}}>0 resultados</h1> :
                        <h1 className='TkSearchView__title-resume' style={manufacturerSitePage?.mainColor ? {color: getContrastColor(manufacturerSitePage.mainColor)} : {}}>
                            {range} {!loading && products ? total : ''} resultado{total > 1 ? 's ' : ' '}
                            <span dangerouslySetInnerHTML={{__html: searchTerm}}/>
                        </h1>
                    }
                </div>
                <div className="col-md-2 t-a-r">
                    <select value={ordering} onChange={orderingChange} className='form-control form-control-sm w-a'>
                        <option value=''>Destaques</option>
                        <option value='priceDesc'>Ordernar por maior preço</option>
                        <option value='priceAsc'>Ordernar por menor preço</option>
                        <option value='nameAsc'>Ordernar por nome</option>
                    </select>
                </div>
            </div>
            <hr className='TkSearchView__hr'/>
            <div className="row" style={manufacturerSitePage?.mainColor ? {backgroundColor: manufacturerSitePage.mainColor} : {}}>
                <div className="col-sm-4 col-md-4 col-lg-2 col-lx-2 TkSearchView__filter-separator" style={manufacturerSitePage?.mainColor ? {color: getContrastColor(manufacturerSitePage.mainColor)} : {}}>
                    <Filters isShowManufacturerPage={!isBlank(manufacturerSitePage?._id)}/>
                </div>
                <div className="col-sm-8 col-md-8 col-lg-10 col-lx-10">
                    {manufacturerSitePage && (!params.has('from') || parseInt(params.get('from')) == 0) ? <ManufacturerHeader manufacturerSitePage={manufacturerSitePage}/> : null}

                    {hasNoResults && <TkHasNoResults/>}

                    {loading && Array(10).fill(null).map((p, idx) => <TkHorizontalCardProduct key={idx} product={p}
                                                                                              showSkeleton={true}/>)}

                    {products && products.map((p, idx) => <TkHorizontalCardProduct key={idx} product={p}
                                                                                   showSkeleton={loading}/>)}

                    {!hasNoResults && <TkPaginator goToUrl={history.push} totalItems={total}/>}
                </div>
            </div>
        </div>
};

export default MediumLargeScreen
