import React, {FC, memo, useCallback, useEffect, useRef, useState} from "react";
import {ITkProductModel} from "../../../models/product";
import {useTkCart, useTkShipment, useTkUser,} from "../../../context/TkContext";
import {ShipmentCost} from "../../../models/shipment";
import {useSimpleInput} from "../../../hooks/UseSimpleInput";
import TkChangeDefaultAddress, {ITkModalChangeDefaultAddress,} from "../../user/TkChangeDefaultAddress";
import DeviceStorage from "../../../utils/storage-utils";
import {isArray, vibrate} from "../../../utils/utils";
import {formatPostalCode, isBlank} from "../../../utils/string-utils";
import Cleave from "cleave.js";
import TkSvgIcon from "../../particles/TkSvgIcon";
import TkSkeleton from "../../particles/TkSkeleton";
import {formatMoney} from "../../../utils/number-utils";
import "./style.scss";
import {calcDateShipment} from "../../../utils/date-utils";

const WithdrawCheckbox: FC = () => {
  const {isWithdraw, setWithdraw} = useTkShipment()
  return <label className='TkShipmentArea__label'
                title={isWithdraw ? 'Desmarque para receber no endereço' : 'Marque para retirar na loja'}>
    <input type="checkbox"
           checked={isWithdraw}
           onChange={() => setWithdraw(!isWithdraw)}
           name="withdraw"/>&nbsp; Retirar na loja
  </label>
}

export enum TkShipmentAreaType {
  cart_detail,
  product_detail,
}

const TkShipmentArea: FC<{
  product?: ITkProductModel;
  quantity?: number;
  type: TkShipmentAreaType;
  isShowInBalloon?: boolean;
  isShowWithdraw?: boolean;
  showSkeleton?: boolean;
}> = ({
  product,
  quantity,
  type,
  isShowInBalloon = false,
  isShowWithdraw = true,
  showSkeleton = false,
}) => {
  const { userAuth, isAuth } = useTkUser();
  const { calculateFromPostalCode, calculateFromCart, calculateWithdraw, isWithdraw } = useTkShipment();
  const [shipment, setShipment] = useState<ShipmentCost>(null);
  const [shipmentError, setShipmentError] = useState(null);
  const [shipmentTimer, setShipmentTimer] = useState(null);
  const [isCalculating, setCalculating] = useState<boolean>(false);
  const isCart = type === TkShipmentAreaType.cart_detail

  const {
    value: postalCode,
    bind: bindPostalCode,
    setValue: setPostalCode,
  } = useSimpleInput("");
  const modalChangeDefaultAddressRef = useRef<ITkModalChangeDefaultAddress>();
  const postalCodeRef = useRef(null);
  const { currentCart, updatingCart } = useTkCart();
  const { setShipmentValue } = useTkShipment();

  const isWithdrawCart = isCart && currentCart?.items?.some(
    ({ isWithdraw }) => isWithdraw
  );

  const isBackorderProduct = quantity > product?.stock && product?.leadTime
  const hasBackorderWithdraw = (isBackorderProduct && isWithdraw) || (!product && currentCart?.items?.some(
    ({ isWithdraw, product, quantity }) => isWithdraw && quantity > product.stock
  ));

  const hasInStockShipment = !!
    ((!isCart && product?.stock) || (isCart &&
      currentCart?.items?.some(
        ({ product, isWithdraw }) => product.stock && !isWithdraw
      )));

  useEffect(() => {
    if (isWithdraw && !isCart) calculateWithdrawDeadline()
    else calculateShipment(postalCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWithdraw, isCart, product, quantity]);

  useEffect(()=> setShipmentValue(shipment), [shipment])

  const isShowInput = useCallback(
    () => !!(isAuth() && userAuth?.user?.defaultAddress),
    [isAuth, userAuth]
  );

  const calculateWithdrawDeadline = async () => {
      setCalculating(true);
      try {
        const result = await calculateWithdraw(postalCode);
        if (result) setShipment(result);
      } catch (error) {
        console.error("Falha no cálculo do prazo de retirada", error);
      } finally{
        setCalculating(false);
      }
  }

  const calculateShipment = useCallback(async (postal: string) => {
      setShipmentError(null);

      if (shipmentTimer) clearTimeout(shipmentTimer);

      setCalculating(true);

      setShipmentTimer(
        setTimeout(async () => {
          try {
            if (postal.replace(/\D+/, "").length === 8) {
              DeviceStorage.putPostalCode(postal);
              if (type === TkShipmentAreaType.product_detail) {
                const backorderQuantity = (quantity > product?.stock && product.leadTime && product.leadTime > 0) ? quantity - product?.stock : 0
                const result = await calculateFromPostalCode(
                  postal,
                  product._id,
                  quantity,
                  backorderQuantity,
                  true
                );
                if (result) setShipment(result);
              } else {
                const result = await calculateFromCart(postal);
                if (result) setShipment(result);
              }
            } else if (type === TkShipmentAreaType.cart_detail) {
              await calculateWithdrawDeadline()
            }
          } catch (e) {
            setShipment(null);
            if (isArray(e)) {
              let msg = e.join("");
              if (msg.indexOf("CEP não") > -1) msg = "CEP não encontrado";
              setShipmentError(msg);
              DeviceStorage.destroyPostalCode();

              vibrate();
            }

            console.error("Falha no cálculo do frete", e);
          } finally {
            setCalculating(false);
          }
        }, 500)
      );

      return () => clearTimeout(shipmentTimer);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      quantity,
      isAuth,
      userAuth,
      shipmentTimer,
      product,
      isWithdraw,
      setShipmentError,
      setShipmentTimer,
      setCalculating,
      calculateFromCart,
      calculateFromPostalCode,
    ]
  );

  useEffect(() => {
    if (!isWithdraw && ((!isCalculating && !shipment) || !updatingCart)) {
      const lastPostalCode = DeviceStorage.getPostalCode || "";
      if (!isShowInput() && lastPostalCode) {
        setPostalCode(formatPostalCode(lastPostalCode));
        calculateShipment(lastPostalCode);
      } else if (userAuth?.user?.defaultAddress) {
        setPostalCode(formatPostalCode(userAuth.user.defaultAddress.postalCode));
        calculateShipment(userAuth.user.defaultAddress.postalCode);
      }
    } else if (isWithdraw) {
      calculateWithdrawDeadline();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPostalCode, updatingCart, quantity, isWithdraw]);

  useEffect(() => {
    let cleave: any;
    if (postalCodeRef.current) {
      cleave = new Cleave(postalCodeRef.current || "", {
        numericOnly: true,
        blocks: [5, 3],
        delimiters: ["-"],
        onValueChanged: ({ target }) => setPostalCode(target.value),
      });
    }

    return () => {
      if (cleave) cleave.destroy();

      cleave = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postalCodeRef.current, setPostalCode]);

  const shipmentArea = () => {
    if (isCalculating) {
      return (
        <div
          className="m-t-20px"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <TkSvgIcon
            icon="sync-solid"
            className="rotate-1-seg TkShipmentArea__svg-size m-r-1em"
          />&nbsp;
          Calculando frete...
        </div>
      );
    }

    if (shipment) {
      let shipmentLoc;

      if (
        shipment.postalCode &&
        shipment?.postalCode?.cityName &&
        shipment?.postalCode?.stateAcronym
      ) {
        shipmentLoc = `${shipment.postalCode.locationType} ${shipment.postalCode.location}, ${shipment.postalCode.cityName} / ${shipment.postalCode.stateAcronym}`;
      } else if (shipment.postalCode?.stateName) {
        shipmentLoc = `${shipment.postalCode.stateName} / ${shipment.postalCode.stateAcronym}`;
      }

      const hasWithdrawInStockProduct = (isWithdrawCart || (product?.stock && isWithdraw)) && shipment.withdrawDeadLine >= 0
      const hasBackorder = hasBackorderWithdraw || shipment.backorderShipment?.value
      const hasInStock = hasWithdrawInStockProduct || hasInStockShipment
      const hasBackorderShipment = ((!product?.stock && product?.leadTime) || (isCart && !!shipment.backorderShipment?.value)) && !isWithdraw
      const outOfStock = !isCart && (!product.isActive || ((product.stock == 0 || (product.controlMultiplicity && product.stock < product.minimumSaleQuantity)) && !product.leadTime) || product.promoPrice==0)
      const limitedDelivery = !isCart && product?.isRestrictedDelivery && (shipment && shipment.postalCode.stateAcronym !== "RS")

      return (
        <>
          <p className="TkShipmentArea__location">{shipmentLoc}</p> 
          {outOfStock && <p className="TkAvailabilityCard TkAvailabilityCard__out-of-stock m-t-10px">Produto Indisponivel</p>}
          {limitedDelivery && !isCart && <p className="TkAvailabilityCard TkAvailabilityCard__out-of-stock m-t-10px">Entrega indisponivel para o endereço</p>}
          {!isCart && !limitedDelivery &&
          <>
          {hasInStock &&
            <div className="TkShipmentArea__order-type-wrapper">
              {isCart && <span>Produtos <span className="in-stock">em estoque:</span></span>}
              {(hasInStockShipment && !isWithdraw) && (
                <div className="dt-value">
                  <span>
                    Entrega:&nbsp;&nbsp;&nbsp;
                    <b>{calcDateShipment(shipment.shipmentDeadLine)}</b>
                  </span>
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;Frete:&nbsp;&nbsp;
                    <b>{formatMoney(shipment.shipmentCost)}</b>
                  </span>
                </div>
              )}
              {hasWithdrawInStockProduct &&
                <div className="dt-value">
                  <span>
                    Retirada:&nbsp;&nbsp;&nbsp;
                    <b>{calcDateShipment(shipment.withdrawDeadLine)}</b>
                  </span>
                  <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;Frete:&nbsp;&nbsp;
                      <b>{formatMoney(0)}</b>
                  </span>
                </div>
              }
            </div>
          }

          {!!hasBackorder && !outOfStock && <div className="TkShipmentArea__order-type-wrapper">
              {isCart && <span>Produtos <span className="backorder">sob encomenda:</span></span>}
              {hasBackorderShipment && (
                <div className="dt-value">
                  <span>Entrega:&nbsp;&nbsp;&nbsp;
                    <b>{calcDateShipment(shipment.backorderShipment?.deadlineDays)}</b>
                  </span>
                  {!!shipment.backorderShipment?.value &&
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;Frete:&nbsp;&nbsp;
                      <b>{formatMoney(shipment.backorderShipment?.value)}</b>
                    </span>
                  }
                </div>
              )}
              {hasBackorderWithdraw &&
                <div className="dt-value">
                  <span>
                    Retirada:&nbsp;&nbsp;
                    <b>{calcDateShipment(shipment.backorderShipment?.withdrawDeadline || ((product?.leadTime || 0) + shipment.withdrawDeadLine))}</b>
                  </span>
                  <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;Frete:&nbsp;&nbsp;
                      <b>{formatMoney(0)}</b>
                  </span>
                </div>
              }
            </div>
          }
          </>
          }
          {isCart && <span className="TkShipmentArea__total-shipment">Total frete:&nbsp;&nbsp;
              <b className="c-p-c">{formatMoney((shipment.shipmentCost || 0) + (shipment.backorderShipment?.value || 0))}</b>
            </span>
          }
        </>
      );
    }

    if (shipmentError)
      return <div className="m-t-20px">
        <b className="c-p-red">{shipmentError}</b>
      </div>

    return <div />;
  };

  function addressArea() {
    if (!isAuth() || !userAuth.user.defaultAddress) return <></>;
    const {
      user: { defaultAddress: address },
    } = userAuth;

    return <>
        {!isShowInput() && <div>
            {address.location}
            {isBlank(address.locationNumber)
              ? ""
              : `, ${address.locationNumber}`}
            {!isBlank(address.postalCode) && (
              <>&nbsp;CEP {formatPostalCode(address.postalCode)}</>
            )}
          </div>
        }
        {isShowWithdraw && product?.isWithdraw && <WithdrawCheckbox/>}
        <div>
          <span
            className="TkShipmentArea__info-change-address"
            title="Alterar endereço padrão"
            onClick={() => modalChangeDefaultAddressRef.current.show()}
          >
            Alterar endereço
          </span>
        </div>
      </>
  }

  const input = <>
      <div className="TkShipmentArea__input-container">
        <input
          type="text"
          disabled={isCalculating || (isWithdraw && !isCart)}
          placeholder="Qual CEP?"
          ref={postalCodeRef}
          className="form-control form-control-sm"
          {...bindPostalCode}
        />
        <button
          type="button"
          disabled={isCalculating || (isWithdraw && !isCart)}
          className="btn btn-ice-gray btn-sm"
          onClick={() => calculateShipment(postalCode)}
        >
          OK
        </button>
      </div>
      {!isShowInBalloon}
      {isShowWithdraw &&
          product?.isWithdraw && <WithdrawCheckbox/>}
    </>

  if (isShowInBalloon) {
    if(showSkeleton){
      return <article className="TkShipmentArea__balloon m-t-10px">
        <TkSkeleton height="25px"/>
        <TkSkeleton height="33px"/>
        {!isCart && <div><div style={{borderTop: "0.1em solid", marginBottom: "0.5em"}}/><TkSkeleton height="35px"/></div>}
      </article>
    }
    return <article className="TkShipmentArea__balloon">
        <h1 className="t-a-c">Consultar entrega e frete</h1>
        {addressArea()}

        <TkChangeDefaultAddress
          ref={modalChangeDefaultAddressRef}
          onPickupAddress={(a) => {
            DeviceStorage.putPostalCode(a.postalCode);
            setPostalCode(formatPostalCode(a.postalCode));
          }}
        />

        {!isShowInput() && input}

        {shipmentArea()}
      </article>
  }

  return <article>
      {addressArea()}

      {!isShowInput() && input}

      {shipmentArea()}

      <TkChangeDefaultAddress
        ref={modalChangeDefaultAddressRef}
        onPickupAddress={(a) => {
          DeviceStorage.putPostalCode(a.postalCode);
          setPostalCode(formatPostalCode(a.postalCode));
        }}
      />
  </article>
};

export default memo(TkShipmentArea);