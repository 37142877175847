import {httpPostGraphQL} from "../../utils/http-utils";
import {getAllManufacturerSitePagesQuery, getManufacturerSitePageBySlugQuery} from "./queries";
import {ITkManufacturerSitePageModel} from "../../models/manufacturerSitePage";

export interface TkManufacturerSitePageContextType {
    getManufacturerSitePageBySlug: (slug: string) => Promise<ITkManufacturerSitePageModel>
    getAllManufacturerSitePages: () => Promise<ITkManufacturerSitePageModel[]>
}
const manufacturerCache = new Map<string, ITkManufacturerSitePageModel>()

const TkManufacturerSitePageContext = (): TkManufacturerSitePageContextType => {
    const getAllManufacturerSitePages = async () => {
        try {
            const {data: result} = await httpPostGraphQL({
                query: getAllManufacturerSitePagesQuery,
            })

            if (result.errors) return Promise.reject(result.errors)

            return result.data.getAllManufacturerSitePages
        } catch (e) {
            console.error('Falha ao consultar páginas dos fabricantes',e)
            return Promise.reject(e)
        }
    }

    const getManufacturerSitePageBySlug = async (slug: string) => {
        try {
            if (manufacturerCache.has(slug)) return manufacturerCache.get(slug)

            const {data: result} = await httpPostGraphQL({
                query: getManufacturerSitePageBySlugQuery,
                variables: {slug}
            })

            if (result.errors) return Promise.reject(result.errors)

            const manufacturerSitePage = result.data.getManufacturerSitePageBySlug

            manufacturerCache.set(slug, manufacturerSitePage)
            return manufacturerSitePage
        } catch (e) {
            console.error('Falha ao consultar páginas dos fabricantes',e)
            return Promise.reject(e)
        }
    }

    return {
        getAllManufacturerSitePages,
        getManufacturerSitePageBySlug,
    }
}

export default TkManufacturerSitePageContext