import {useTkProduct} from "../../context/TkContext";
import {useCallback} from "react";
import {useLocation} from "react-router-dom";
import {isBlank} from "../../utils/string-utils";
import {ITkIdNameModel, ITkProductModel} from "../../models/product";


const useTkSearchView = () => {
    const {search, pathname} = useLocation();
    const {
        state: {manufacturers, families, classes, groups, isLoadingClassifiers}
    } = useTkProduct();

    const getTitleByIds = useCallback((arr: ITkIdNameModel[], ...ids: string[]) => {
        if (isLoadingClassifiers) return []

        if (ids.length > 0 && arr.length > 0) return arr.filter((item) => ids.includes(item._id)).map((item) => item.name)

        return []
    }, [isLoadingClassifiers])

    const multipleNamesFormatter = useCallback((names: string[]) => {
        if (names.length === 1) return names[0]
        else if (names.length > 1) {
            const allExceptLast = names.slice(0, -1).join(', ');
            const lastItem = names[names.length - 1];
            return `${allExceptLast} e ${lastItem}`;
        }

        return ''
    }, [])

    const makeKeywords = useCallback((...products: ITkProductModel[]): string => {
        const productNames = new Set<string>()
        const keywords = []
        if (products.length > 0) {
            products.forEach((product) => {
                productNames.add(product.mainName)
            })
            products.forEach((product) => {
                productNames.add(product.manufacturerName)
            })
            keywords.push(...Array.from(productNames))
        }
        const params = new URLSearchParams(search);
        const divisionName = params.get('divisionName');

        if (!isBlank(divisionName)) keywords.push(divisionName)

        if (!isLoadingClassifiers) {
            const manufacturersIds = params.get('manufacturersIds');
            const familyIds = params.get('familiesIds');
            const classesIds = params.get('classesIds');
            const groupsIds = params.get('groupsIds');

            if (!isBlank(manufacturersIds)) {
                const ids = manufacturersIds.split(',')
                keywords.push(...getTitleByIds(manufacturers, ...ids))
            }

            if (!isBlank(familyIds)) {
                const ids = familyIds.split(',')
                keywords.push(...getTitleByIds(families, ...ids))
            }

            if (!isBlank(classesIds)) {
                const ids = classesIds.split(',')
                keywords.push(...getTitleByIds(classes, ...ids))
            }

            if (!isBlank(groupsIds)) {
                const ids = groupsIds.split(',')
                keywords.push(...getTitleByIds(groups, ...ids))
            }
        }

        return Array.from(new Set(keywords).values()).join(', ')
    }, [search, manufacturers, families, classes, groups, isLoadingClassifiers])

    const makeTitle = useCallback(() => {
        if (isLoadingClassifiers) return ''
        const params = new URLSearchParams(search);
        const term = params.get('term');
        const divisionName = params.get('divisionName');
        const manufacturersIds = params.get('manufacturersIds');
        const familyIds = params.get('familiesIds');
        const classesIds = params.get('classesIds');
        const groupsIds = params.get('groupsIds');
        const title: string[] = [];

        if (!isBlank(term)) title.push(term)

        if (!isBlank(manufacturersIds)) {

            if (pathname === '/parceiros/fabricantes') {

            } else {
                const ids = manufacturersIds.split(',')
                const names = getTitleByIds(manufacturers, ...ids)
                if (title.length > 0 && names.length > 0) {
                    if (names.length === 1) title.push(` ${names[0]}`)
                    else title.push(` nas marcas ${multipleNamesFormatter(names)}`)
                } else {
                    title.push(' ', multipleNamesFormatter(names))
                }
            }
        } else if (!isBlank(divisionName)) {
            if (title.length > 0) title.push(` ${divisionName}`)
            else title.push('Produtos da divisão ', divisionName)
        } else if (!isBlank(familyIds)) {
            const ids = familyIds.split(',')
            const names = getTitleByIds(families, ...ids)
            if (title.length > 0 && names.length > 0) {
                if (names.length === 1) title.push(` ${names[0]}`)
                else title.push(` ${names.length > 1 ? 'nas famílias' : 'na família'}`,` ${multipleNamesFormatter(names)}`)
            } else {
                title.push(' ', multipleNamesFormatter(names))
            }
        } else if (!isBlank(classesIds)) {
            const ids = classesIds.split(',')
            const names = getTitleByIds(classes, ...ids)
            if (title.length > 0 && names.length > 0) {
                if (names.length === 1) title.push(` ${names[0]}`)
                else title.push(` ${names.length > 1 ? 'nas classes' : 'na classe'}`,` ${multipleNamesFormatter(names)}`)
            } else {
                title.push(' ', multipleNamesFormatter(names))
            }
        } else if (!isBlank(groupsIds)) {
            const ids = groupsIds.split(',')
            const names = getTitleByIds(groups, ...ids)
            if (title.length > 0 && names.length > 0) {
                if (names.length === 1) title.push(` ${names[0]}`)
                else title.push(` ${names.length > 1 ? 'nos grupos' : 'no grupo'}`,` ${multipleNamesFormatter(names)}`)
            } else {
                title.push(' ', multipleNamesFormatter(names))
            }
        }
        return title.join('')
    }, [manufacturers, families, classes, groups, isLoadingClassifiers, search, getTitleByIds]);


    return {
        makeTitle,
        makeKeywords,
    }
}


export default useTkSearchView;